import React,{Suspense,lazy} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route , Switch} from 'react-router-dom';
import { createStore,compose } from 'redux';
import {loadState,saveState} from './Redux/reduxState.js';
import allReducers from "./Redux/Reducers/index.js";
import throttle from 'lodash.throttle';
import {Provider} from 'react-redux';


const enhancers = compose(
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const previousState=loadState();
const store=createStore(
	allReducers,
	previousState,
	enhancers
);

store.subscribe(throttle(() =>{
  saveState(store.getState());
}, 1000));


const Landing=lazy(()=>import('./Components/Pages/Landing/Container/index.js'));
const SignUp=lazy(()=>import('./Components/Pages/SignUp/Container/index.js'));
const Settings=lazy(()=>import('./Components/Pages/Settings/Container/index.js'));
const Analytics=lazy(()=>import('./Components/Pages/Analytics/AnalyticCards/AnalyticsSet/index.js'));
const SpecifiedAnalytics=lazy(()=>import('./Components/Pages/Analytics/SpecifiedAnalytic/AnalyticSet/index.js'));
const Dashboard=lazy(()=>import('./Components/Pages/Dashboard/Container/index.js'));

const Creation=lazy(()=>import('./Components/Pages/Creation/Container/index.js'));

const ReticanDetails=lazy(()=>import('./Components/Pages/ReticanDetails/DetailsSet/index.js'));
const PrivacyPolicy=React.lazy(()=>import('./Components/Pages/LegalDocuments/PrivacyPolicy/index.js'));
const TermsOfConditions=React.lazy(()=>import('./Components/Pages/LegalDocuments/TermsOfService/index.js'));
const ReviewRetican=React.lazy(()=>import('./Components/Pages/Creation/Container/index.js'));
const Pricing=React.lazy(()=>import('./Components/Pages/Pricing/Container/index.js'));
const Checkout=React.lazy(()=>import('./Components/Pages/Checkout/Container/index.js'));
const PasswordReset=React.lazy(()=>import('./Components/Pages/PasswordReset/Container/index.js'));
const Responses=React.lazy(()=>import('./Components/Pages/Responses/Container/index.js'));

/*
	<Route exact path="/pricing" component={Pricing}/>
	<Route exact path="/privacyPolicy" component={PrivacyPolicy}/>
	<Route exact path="/termsOfService" component={TermsOfConditions}/>
	<Route exact path="/analytics" component={Analytics}/>
	<Route exact path="/analytics/:id" component={SpecifiedAnalytics}/>

	<Route exact path="/checkout" component={Checkout}/>
*/


const application=(
	<Provider store={store}>
		<Router>
			<Suspense fallback={<p>Atlesium</p>}>
				<Switch>
					<Route exact path="/" component={Landing}/>
					<Route exact path="/signup" component={SignUp}/>
					<Route exact path="/settings" component={Settings}/>
					<Route exact path="/dashboard" component={Dashboard}/>
					<Route exact path="/creation" component={Creation}/>
					<Route exact path="/creation/:id" component={Creation}/>
					<Route exact path="/privacy" component={PrivacyPolicy}/>
					<Route exact path="/terms" component={TermsOfConditions}/>
					<Route exact path="/password" component={PasswordReset}/>
					<Route exact path="/password/:id" component={PasswordReset}/>
					<Route exact path="/responses/" component={Responses}/>
				</Switch>
			</Suspense>
		</Router>
	</Provider>
);

ReactDOM.render(application,document.getElementById('App'));