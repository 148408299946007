const initialState={
	token:""
}


const PersonalInformationReducer=(state={...initialState},action)=>{
	const {
		type,
		payload
	}=action;
	switch(type){
		case 'UPDATE_TOKEN':{
			return {
				...state,
				token:payload
			}
		}

		case 'LOGOUT_USER':{
			return{
				token:""
			}
		}

		default:{
			return state;
		}
	}
}


export default PersonalInformationReducer;